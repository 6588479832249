import React, { FC } from 'react';
import { getResource } from '../../Constants';
import Hr from '../shared/Hr';
import styles from './Brand.module.scss';

interface IBrandContent {
    brandName: string;
    brandDescription: string;
    brandColor: string;
    modelQr: string;
}

const BrandContent: FC<IBrandContent> = ({brandName, brandDescription, brandColor, modelQr}) => {
    return (
        <article className={`${styles.brandDescription} is-flex is-flex-direction-column is-justify-content-space-between`} style={{backgroundColor: '#000'}}>
            <h1 data-scroll data-scroll-speed="1">{brandName}</h1>
            <div className={styles.brandContent} data-scroll>
                <p dangerouslySetInnerHTML={{ __html: brandDescription }}></p>
            </div>
            <Hr />
            <ul className={`${styles.brandSocial} is-flex is-flex-direction-row is-align-items-center`}>
              <li className={`pr-5 is-flex-grow-2`}>
                <img src={'/museo/assets/images/ra.png'} alt="" />
              </li>
              <li>
                <img src={getResource('qr', modelQr)} className={styles.qr} />
              </li>
            </ul>
        </article>
    );
};

export default BrandContent;
