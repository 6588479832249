import React, {useRef, useMemo, useEffect, useState, FC} from 'react';
import { getResource } from '../../Constants';
import I360 from '../shared/I360';

interface IVideo360 {
  video: string;
  audio: string;
  onLoad: Function;
  canPlay: boolean;
  audioStart?: number;
}

const Video360: FC<IVideo360> = ({video, onLoad, canPlay, audio, audioStart = 0}) => {
  const videoRef: any = useRef();
  const audioRef: any = useRef();
  const [player, setPlayer] = useState();
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const yesCanPLay = useMemo(() => isAudioReady && canPlay && isVideoReady, [canPlay, isAudioReady, isVideoReady]);


  useEffect(() => {


    if(!player) {
        videoRef.current.onloadstart = () => {
            //player && (player as any).dispose();
            const currentPlayer = (window as any).videojs(videoRef.current, {
                plugins: {
                pannellum: {
                    mouseZoom: false,
                    autoLoad : true,
                    compass: false,
                    showFullscreenCtrl: true,
                    autoRotate: false
                  },
                },
            },
            function() {
              setIsVideoReady(true);
              onLoad();
            });

            setPlayer(currentPlayer);
        };
    }

    return (player as any)?.dispose;
  }, [videoRef, player, setPlayer, setIsVideoReady]);


  useEffect(() => {
    if(audioRef){
      audioRef.current.addEventListener('canplaythrough', function() {
        setIsAudioReady(true);
      });
    }
  }, [audioRef, setIsAudioReady])

  useEffect(()=> {
    if(yesCanPLay){
      videoRef.current.play();
      if(audio){
        audioRef.current.currentTime = audioStart || 0;
        audioRef.current.play();
      } else{
        videoRef.current.volume = 0.5;
        videoRef.current.muted = false;
      }
    }
  }, [yesCanPLay, videoRef, audioRef, audio]);

  return useMemo(
    () => (
      <>
      <video
        id="panorama"
        className="video-js vjs-default-skin vjs-big-play-centered"
        ref={videoRef}
        controls={false}
        preload={'auto'}
        style={{width: '100%', height: '100vh'}}
        crossOrigin="anonymous"
        autoPlay={true}
        loop={true}
        muted={true}
      >
        <source src={getResource('360_videos',`${video}.webm`)} type="video/webm" />
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that{' '}
          <a href="http://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
          
        </p>
      </video>
      {
        audio ? (
          <audio controls={false} loop={true} ref={audioRef} preload={'auto'}>
          <source src={getResource('audio',audio)} type="audio/ogg" />
        </audio>
        ): <span ref={audioRef}></span>
      }
      <I360 />
      </>
    ),
    [video, videoRef, audioRef, audio]
  );
};

export default Video360;
