import { useCallback } from "react";
import Actions from "../ActionTypes";
import { useStateValue } from "../main";

export const useSetOpenRooms = () => {
    const [_, dispatch] = useStateValue();
    return useCallback(
      (openRooms: number []) =>
        dispatch({ type: Actions.SET_OPEN_ROOMS, payload: openRooms }),
      [dispatch]
    );
  };
