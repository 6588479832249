import { useEffect, useState } from 'react';
import { fetchDesigner } from '../services/designer.service';
import { IDesigner } from '../types';

export const useDesigner = (designerId: string) => {
    const [designer, setDesigner] = useState({} as IDesigner);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async()=> {
            setLoading(true);
            const designer = await fetchDesigner(designerId);
            setDesigner(designer);
            setLoading(false);
        })();
    }, [designerId, setDesigner]);

    return {designer, loading };
}