import React, {FC} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AudioLobby from './components/AudioLobby';
import {useViewLobby} from './hooks/viewHooks';
import {DetailLanding} from './pages/detail/DetailLanding';
import Lobby from './pages/lobby/Lobby';
import Room from './pages/room/Room';
import Winner from './pages/winner/Winner';

interface IAppRouter {}
export const AppRouter: FC = () => {
  const viewLobby = useViewLobby();

  return (
    <main>
      <Router basename={'/museo'}>
        <AudioLobby />
        <Switch>
          <Route path="/designer/:designerId" component={DetailLanding} exact={true} />
          <Route path="/room/:roomId" component={Room} exact={true} />
          <Route path="/" exact={true} component={Lobby} />
          <Route path="/end" exact={true} component={Winner} />
        </Switch>
      </Router>
    </main>
  );
};

export default AppRouter;
