import React, { FC, useEffect, useRef } from 'react';
import { getResource } from '../Constants';
import styles from './VideoModal.module.scss';

interface IVideModal{

    onEnd: any;
    video: string;
    bucketName?: string;
    onStart?: any;
}

const VideoModal: FC<IVideModal> = ({onEnd, onStart, video, bucketName = 'intros'}) => {
    const ref = useRef();
    useEffect(()=> {
        if(ref.current){
            (ref.current as any).addEventListener('ended', () => {
                onEnd();
            });
            (ref.current as any).addEventListener('click', () => {
              (ref.current as any).play();
            });

            if(onStart){
              (ref.current as any).addEventListener('play', () => {
                onStart();
              });
            }


            (ref.current as any).play();
        }
    }, [ref, onEnd, onStart]);

    useEffect(() => {
      if(ref.current){
        console.log('video changed', video);
        const reff:any = ref.current;
        reff.currentTime = 0;
        reff.play();
      }
    }, [ref, video])

    return (
        <div className={styles.modal}>
            <video
        className=""
        ref={ref as any}
        controls={false}
        preload={'auto'}
        crossOrigin="anonymous"
        autoPlay={false}
        loop={false}
        muted={false}
      >
        <source src={getResource(bucketName, video)} type="video/mp4" />
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that{' '}
          <a href="http://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
          
        </p>
      </video>
        </div>
    )
};


export default VideoModal;

