import React from 'react';
import AppRouter from './App.router';
import initialState from './stores/main/initialState';
import { StateProvider } from './stores/main/main';
import { reducer } from './stores/main/reducers';

function App() {
  return (
    <StateProvider reducer={reducer} initialState={initialState}>
          <AppRouter />
    </StateProvider>
  );
}

export default App;
