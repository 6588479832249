import React, { FC, useMemo } from "react";
import { getResource } from "../../Constants";
import { IDesigner } from "../../types";
import Hr from "../shared/Hr";
import styles from "./Designer.module.scss";
const Designer: FC<IDesigner> = ({
  designerColor,
  designerName,
  designerPhoto,
  designerSummary,
  designerQr,
  architecture2,
  instagram,
  instagram2,
  mail,
  webpage
}) => {
  const parts = useMemo(() => designerName.split('').map((letter, index) => (
    {
      letter,
      delay: .04 + (designerName.length - index) * 0.01
    }
  )), [designerName]);

  const behance = useMemo(()=> {
    const parts = webpage?.split('/');
    return parts && parts[(parts?.length || 1) -1];
  }, [webpage]);
  
  return (
    <section className={`is-flex pt-6 ${styles.container} is-cover`} style={{backgroundImage: `url(${getResource('scenes', architecture2)})`}}>
      <figure
        className={styles.designerPhoto}
      >
        <img src={getResource('scenes', designerPhoto)} alt="" />
      </figure>
      <article
        className={`${styles.designerDescription} is-flex is-flex-direction-column is-justify-content-space-between`}
        style={{ backgroundColor: designerColor }}
      >
        <h1 className={styles.designerTitle}
        
        >
          {designerName}
          
        </h1>
        <div className={styles.designerContent}>
          <p dangerouslySetInnerHTML={{ __html: designerSummary }}></p>
        </div>
        <Hr />
        <br />
        <div className={`${styles.designerSocial} is-flex is-flex-direction-row`} >
          <img src={`${getResource('qr', designerQr)}`} className={styles.qr} />
          <ul className={`is-flex is-justify-content-space-between is-flex-direction-column`}>
              {instagram && <li>
                <a href={`https://www.instagram.com/${instagram}`} target="_blank">
                  <span className={styles.icon}>
                    <i className="fab fa-instagram-square"></i>
                  </span>
                  @{instagram}
                </a>
              </li>}
               {instagram2 && <li>
                <a href={`https://www.instagram.com/${instagram2}`} target="_blank">
                  <span className={styles.icon}>
                    <i className="fab fa-instagram-square"></i>
                  </span>
                  @{instagram2}
                </a>
              </li>}
              {mail && <li>
                <a href={`mail:${mail}`}>
                  <span className={styles.icon}>
                    <i className="far fa-envelope"></i>
                  </span>
                  {mail}
                </a>
              </li>}
              {webpage && <li>
                <a href={webpage} target="_blank">
                  <span className={styles.icon}>
                    <i className="fas fa-globe"></i>
                  </span>
                  {behance}
                </a>
              </li>}
            </ul>
          
        </div>
      </article>
    </section>)
  ;
};
export default Designer;
