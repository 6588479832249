import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import VideoModal from '../../components/VideoModal';
import { useAnalyticsEnter, useLog } from '../../hooks/analyticsHooks';
import { useKey } from '../../hooks/toolsHooks';

const videos = [
    'SALIDA.webm',
    'winner.mp4',
    'SALIDA_despedida.webm'
]

const key = 'user_ended';

const Winner: FC = () => {
    const winKey = useKey(key);
    const [video, setVideo] = useState(0);
    const [loadedVideo, setLoadedVideo] = useState(false);
    const [finaVideo, serFinal] = useState(videos[0]);
    const videoRef = useRef();
    const logOnEndStart = useLog(key);

    useAnalyticsEnter('/end', 'winner');

    const onEnd = useCallback(()=> {
        const index = Math.min(video+1, videos.length -1);
        setVideo(index);
    }, [video, setVideo]);

    useEffect(() => {
        serFinal(videos[video]);
    }, [video]);

    useEffect(()=> {
        //log the first time userStartViewEnd
        if(!localStorage[winKey]){
            logOnEndStart();
            localStorage[winKey] = 'true';
        }
    }, [logOnEndStart, winKey])

    useEffect(()=> {
        (async() => {
            const vid = 'ALEXA KUSULAS WINNER.webm';
            videos[1] = vid;
            setLoadedVideo(true)
        })();
    }, [setLoadedVideo]);

    return useMemo(() =>(
        <>
            {loadedVideo && <VideoModal onEnd={onEnd} video={finaVideo} bucketName="winner" key={`video-${video}`} />}
        </>
    ), [finaVideo, videoRef, video, loadedVideo])
}

export default Winner;
