import React, {FC, useMemo} from 'react';
import BrandContent from '../../components/content/Brand';
import ModelComponent from '../../components/Model';
import { getResource } from '../../Constants';
import {IDesigner} from '../../types';
import styles from './ModelView.module.scss';

interface IModelView {
  designer: IDesigner;
  onLoad: Function;
  descriptionKey?: 'brandDescription' | 'brandDescription2';
  modelKey?: 'model' | 'model2';
  zoom?: number;
}

export const ModelView: FC<IModelView> = ({
  designer,
  onLoad,
  descriptionKey = 'brandDescription',
  modelKey = 'model',
  zoom = 0.5,
  ...props
}) => {
  const {brandName, brandColor} = designer;

  


  const [qr, key] = useMemo(()=> {
    const key = modelKey === 'model' ? 'modelQr':'model2Qr';
    return [designer[key], key];
  }, [modelKey, designer]);

  return (
    <section
      className={`hero is-fullheight is-relative is-flex is-flex-direction-row is-justify-content-flex-start is-cover ${styles.container}`}
      style={{backgroundImage: `url(${getResource('scenes',designer.architecture)}`}}
      {...props}
    >
      <BrandContent brandDescription={designer[descriptionKey]} brandName={brandName} brandColor={brandColor} modelQr={qr} />
      <ModelComponent modelFile={designer[modelKey]} onLoad={onLoad} zoom={zoom} />
    </section>
  );
};
