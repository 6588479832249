import { Action } from '@babylonjs/core';
import Actions from './ActionTypes';
import { IFashionWeekAction, IFashionWeekState, IUser } from "./types/StoreTypes";

// TODO: split into different reducers

export const reducer = (state: IFashionWeekState, {payload, type}: IFashionWeekAction): IFashionWeekState => {
    switch(type) {
        case Actions.LOGIN:
            return {...state, isLoggedIn: true, user: payload};
        case Actions.LOGOUT:
            return {...state, isLoggedIn: false, user: {} as IUser};
        case Actions.SET_CURRENT_ROUTE:
            return {...state, currentRoute: payload};
        case Actions.SET_MANAGED_ROUTE:
            return {...state, managedRoute: payload};
        case Actions.SET_IS_USER:
            return {...state, isUser: payload, knowsIsUser: true};
        case Actions.SET_IS_ADMIN:
            return {...state, isAdmin: payload, knowsIsAdmin: true};
        case Actions.SET_IS_JUDGE:
            return {...state, isJudge: payload, knowsIsJudge: true};
        case Actions.SET_IS_PREVIEW:
            return {...state, isPreview: payload};
        case Actions.SET_IS_MIDEC:
            return {...state, isMidec: payload};
        case Actions.SET_OPEN_ROOMS:
            return {...state, openRooms: payload};
        case Actions.SET_LOBBY_AUDIO:
            return {...state, isLobbyAudio: payload};
    }
    
    return state;
};
