import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

interface IHeader {
    variant?: 'box' | 'fixed';
}

const Header: FC<IHeader> = ({variant = 'fixed'}) => (
    <header className={`${styles.header} pt-6 ${styles[variant]}`}>
        <Link className={`${styles.h1}`} to={'/'}>© CEDIM <span>2021 Virtual Runway</span></Link>
    </header>
);

export default Header;
