import { IRoom } from "../types/room.types";

const rooms: Record<string, IRoom> = {
    '1': {
        roomId: '1',
        name: 'HERE TO CHANGE THE WORLD',
        background: 'back_menu_sala_1.jpg',
        color: '#06AEC2',
        designers: [
            {
                id: '1',
                name: 'Yesica Arlette Soto',
                brandName: "Desde el corazón",
                hoverImage: "disena.jpg"
            },
            {
                id: '25',
                name: 'Ana Cecilia Rodríguez Benítez',
                brandName: "Dacia Studio",
                hoverImage: "menu_sala_6_3_ana_cecilia.jpg"
            },
            {
                id: '23',
                name: 'Noré Torres Castillo',
                brandName: "Noré Torres",
                hoverImage: "menu_sala_6_1_nore.jpg"
            },
            {
                id: '24',
                name: 'Aldara González López',
                brandName: "Aldara González",
                hoverImage: "menu_sala_6_2_aldara.jpg"
            },
            {
                id: '26',
                name: 'Daniela Eloisa Cruz Cervantes',
                brandName: "Daniela Cruz",
                hoverImage: "menu_sala_6_4_daniela.jpg"
            },
            {
                id: '27',
                name: 'Lorena de la Garza Mota',
                brandName: "Azragalde",
                hoverImage: "menu_sala_6_5_lorena.jpg"
            }
            
        ]
    },
    '2': {
        roomId: '2',
        name: 'HUMAN INTERACTION',
        background: 'back_menu_sala_2.jpg',
        color: 'rgb(0, 77, 181)',
        designers: [
            {
                id: '6',
                name: 'Andrea De Nigris ',
                brandName: "Adaqua Swimwear",
                hoverImage: "menu_sala_2_1_andrea.jpg"
            },
            {
                id: '7',
                name: 'Ximena Saucedo',
                brandName: "ACHE",
                hoverImage: "menu_sala_2_2_ximena.jpg"
            },
            {
                id: '8',
                name: 'Nickol Paulina González',
                brandName: "MALAKO",
                hoverImage: "menu_sala_2_3_nickol.jpg"
            },
            {
                id: '9',
                name: 'Ana Laura Cárcamo',
                brandName: "Aura",
                hoverImage: "menu_sala_2_4_analaura.jpg"
            }
        ]
    },
    '3': {
        roomId: '3',
        name: 'WE ARE MISUNDERSTOOD',
        background: 'back_menu_sala_3.jpg',
        color: '#DF6185',
        designers: [
            {
                id: '10',
                name: 'Sofía Alejandra Aguirre',
                brandName: "Sofía Cardoso",
                hoverImage: "menu_sala_3_1_sofiaguirre.jpg"
            },
            {
                id: '11',
                name: ' Lucia Gil González',
                brandName: "LUCIA",
                hoverImage: "menu_sala_3_2_lucia.jpg"
            },
            {
                id: '12',
                name: 'Sofía Guerrero',
                brandName: "Fashion Killa",
                hoverImage: "menu_sala_3_3_sofiaguerrero.jpg"
            },
            {
                id: '13',
                name: 'Esteban Madero',
                brandName: "Uncaged",
                hoverImage: "menu_sala_3_4_esteban.jpg"
            }
        ]
    },
    '4': {
        roomId: '4',
        name: 'BEIGN YOURSELF',
        background: 'back_menu_sala_4.jpg',
        color: '#DE464A',
        designers: [
            {
                id: '14',
                name: 'Sara Bazaldúa Cavazos',
                brandName: "Kiki",
                hoverImage: "menu_sala_4_1_sara.jpg"
            },
            {
                id: '15',
                name: 'Nohemi Medina',
                brandName: "Nohemi Medina",
                hoverImage: "menu_sala_4_2_nohemi.jpg"
            },
            {
                id: '16',
                name: 'Ana Victoria Ruíz Garza',
                brandName: "Ana Victoria Ruíz",
                hoverImage: "menu_sala_4_3_anavictoria.jpg"
            },
            {
                id: '17',
                name: 'Einny Yasmin Gutiérrez Sánchez',
                brandName: "HEY-NYY ",
                hoverImage: "menu_sala_4_4_einny.jpg"
            },
            {
                id: '18',
                name: 'Paula Mariana Ortiz Castañeda',
                brandName: "Dulcet Fairy",
                hoverImage: "menu_sala_4_5_paula.jpg"
            }
        ]
    },
    '5': {
        roomId: '5',
        name: 'EMBRACE YOURSELF',
        background: 'back_menu_sala_5.jpg',
        color: '#f9a30e',
        designers: [
            {
                id: '19',
                name: 'Carolina López Segura',
                brandName: "Carolina López Segura",
                hoverImage: "menu_sala_5_1_carolina.jpg"
            },
            {
                id: '20',
                name: ' Paulina Verónica López Belmontes',
                brandName: "Vangiel",
                hoverImage: "menu_sala_5_2_paulina.jpg"
            },
            {
                id: '21',
                name: 'Luis Ricardo',
                brandName: "Ricardo Calderoni",
                hoverImage: "menu_sala_5_3_ricardo.jpg"
            },
            {
                id: '22',
                name: 'Leslie Mariana Manzo',
                brandName: "M a r · M a n · C a",
                hoverImage: "menu_sala_5_4_leslie.jpg"
            }
        ]
    },
    '6': {
        roomId: '6',
        name: 'WE ARE THE FUTRE',
        background: 'back_menu_sala_6.jpg',
              color: '#F6D72F',
        designers: [
             
            {
                id: '2',
                name: 'Nora Nieblas',
                brandName: "Thats.so.nora",
                hoverImage: "02_Nora_Nieblas_Concepto_Menu.png"
            },
            {
                id: '3',
                name: 'Ximena Arias Macias',
                brandName: "Abstract Occult",
                hoverImage: "menu_sala_1_3_ximena.jpg"
            },
            {
                id: '4',
                name: 'Valeria Aguilar',
                brandName: "Unexpected People",
                hoverImage: "menu_sala_1_4_valeria.jpg"
            },
            {
                id: '5',
                name: 'Alexa Kusulas',
                brandName: "Alexa Kusulas",
                hoverImage: "menu_sala_1_5_alexa.jpg"
            }
        ]
    }
};

export const onlyNames: Record<string, string> = Object.values(rooms).map(room => room.designers.map(({id, name}) => (
    {id, name}
))).flat().reduce((onames, designer) => ({...onames, [designer.id]: designer.name}), {});

export const winnerVideos: Record<string, string> = {
    '2': 'NORA.webm',
    '5': 'ALEXA KUSULAS WINNER.webm',
    '23': 'nore_winner.webm',
    '25': 'ANA CECILIA WINNER_1.webm',
    '27': 'LORENA WINNER.webm'
};

export const onlyWinnerIds = Object.keys(winnerVideos);

export default rooms;
