import React, { FC } from "react";
import Designer from "../../components/content/Designer";
import { IDesigner } from "../../types";
import styles from "./DesignerPage.module.css";

interface IDesignerPage {
  designer: IDesigner;
}

export const DesignerPage: FC<IDesignerPage> = ({ designer, ...props }) => (
  <section className={`hero is-fullheight ${styles.designerPage}`} {...props}>
    <Designer {...designer} />
  </section>
);
