import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import rooms from '../../_data/rooms.data';
import CursorClass from '../../lib/images/cursor';

import styles from './Lobby.module.scss';
import I360 from '../../components/shared/I360';
import Header from '../../components/layout/Header';
import VideoModal from '../../components/VideoModal';
import { useAnalyticsEnter, useLog } from '../../hooks/analyticsHooks';
import { useKey, usePrefix } from '../../hooks/toolsHooks';
import { useStateValue } from '../../stores/main/main';
import Actions from '../../stores/main/ActionTypes';

const points = [
    {
        "pitch": -5,
        "yaw": 68
    },
    {
        "pitch": -5,
        "yaw": 114
    },
    {
        "pitch": -5,
        "yaw": 158
    },
    {
        "pitch": -5,
        "yaw": 202
    },
    {
        "pitch": -5,
        "yaw": 247
    },
    {
        "pitch": -5,
        "yaw": 292
    }
];

const introKey = `lobby-intro`;

const Lobby: FC = () => {
    const ref = useRef();
    const key = useKey(introKey);
    const cursorRef = useRef();
    const history = useHistory();
    const [intro, setIntro] = useState(!localStorage[key]);
    const [_, dispatch] = useStateValue();

    const endIntroLog = useLog('end_intro');
    const startedIntroLog = useLog('started_event');
    
    const hideIntro = useCallback(()=> {
        setIntro(false);
        localStorage[key] = 'true';
        endIntroLog();
    }, [setIntro, endIntroLog, key]);

    const hotspot = useCallback((roomId) => (hotSpotDiv: HTMLDivElement, args: any) => {
        hotSpotDiv.addEventListener('click', () => history.push(`/room/${roomId}`));
        new CursorClass(cursorRef?.current, hotSpotDiv as any);
    }, [cursorRef]);

    useAnalyticsEnter('/lobby', 'lobby');

    useEffect(() => {
        ref.current && (window as any).pannellum.viewer(ref.current, {
            "type": "equirectangular",
            "panorama": "/museo/assets/images/lobby.png",
            "autoLoad": true,
            "showControls": false,
            "compass": false,
            "hotSpots": Object.values(rooms).map(({roomId, name}, index) => ({
                ...points[index],
                roomId,
                scale: true,
                showZoomCtrl: false,
                friction: 0.05,
                cssClass: styles.customHotspot,
                createTooltipFunc: hotspot(roomId)
            }))
        });

    }, [ref]);

    useEffect(() => {
        dispatch({type: Actions.SET_LOBBY_AUDIO, payload: !intro});
        if(intro){
            startedIntroLog();
        }
    }, [intro, startedIntroLog, dispatch]);


    return(
        <>
            <Header />
            <div className={styles.lobby} ref={ref as any}>
                <I360 />
            </div>

            <div className={`cursor ${styles.spinnerWrap}`} ref={cursorRef as any}>
                <div className={`${styles.spinner}`}>
                    <div className={styles.innerSpinner}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    );
}



export default Lobby;
