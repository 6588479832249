import React, {FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Header from '../../components/layout/Header';
import VideoModal from '../../components/VideoModal';
import { getResource } from '../../Constants';
import {useAnalyticsEnter, useLog} from '../../hooks/analyticsHooks';
import {useDesigner} from '../../hooks/designerHooks';
import { useKey } from '../../hooks/toolsHooks';
import {useStateValue} from '../../stores/main/main';
import {DesignerPage} from './DesignerPage';
import {ModelView} from './ModelView';
import {View360} from './View360';

const designerKeyL = 'designer-intro';

export const DetailLanding: FC = () => {
  const {designerId}: any = useParams();
  const designerKey = useKey(designerKeyL);
  const {loading, designer} = useDesigner(designerId);
  const [intro, setIntro] = useState(false);
  const [{isJudge}] = useStateValue();
  const logIntroEnd = useLog('intro_designer_end');
  const hideIntro = useCallback(() => {
    setIntro(false);
    localStorage[`${designerKey}-${designerId}`] = 'true';
    logIntroEnd({designerId: designerId});
  }, [setIntro, logIntroEnd, designerId]);

  const callback = useCallback(() => {}, []);

  useAnalyticsEnter('/designer', designerId);

  return (
    <>
      <Header />
      <section
        className={`is-cover`}
        style={{
          backgroundImage: `url(${getResource('scenes',designer.architecture)}`,
        }}
      >
        {!loading && designer.designerName ? (
          <>
            <section className="hero is-fullheight">
              <div className="hero is-fullheight" style={{position: 'fixed', left: 0, top: 0, width: '100vw'}}>
                <View360 designer={designer} onLoad={callback} canPlay={!intro} />
              </div>
            </section>
            {designerId !== '1' && (
              <>
                <ModelView
                  designer={designer}
                  onLoad={callback}
                  zoom={designer.modelZoom ?? 0.5}
                />
                <ModelView
                  designer={designer}
                  onLoad={callback}
                  modelKey={'model2'}
                  descriptionKey={'brandDescription2'}
                  zoom={designer.model2Zoom ?? 0.5}
                />
                <DesignerPage designer={designer} />
                <section className="hero is-fullheight"></section>
              </>
            )}
          </>
        ) : (
          <div>loading...</div>
        )}
      </section>
    </>
  );
};
