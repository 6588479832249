enum Actions {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE',
    SET_MANAGED_ROUTE = 'SET_MANAGED_ROUTE',
    SET_IS_USER = 'SET_IS_USER',
    SET_IS_ADMIN = 'SET_IS_ADMIN',
    SET_IS_JUDGE = 'SET_IS_JUDGE',
    SET_IS_PREVIEW = 'SET_IS_PREVIEW',
    SET_IS_MIDEC = 'SET_IS_MIDEC',
    SET_OPEN_ROOMS = 'SET_OPEN_ROOMS',
    SET_LOBBY_AUDIO = 'SET_LOBBY_AUDIO',
}

export default Actions;
