import React, { FC, useCallback, useMemo, useState } from 'react';
import { FreeCamera, Vector3, HemisphericLight, MeshBuilder, SceneLoader, Color4, AssetContainer, ArcRotateCamera, PointLight, Color3 } from '@babylonjs/core';
import SceneComponent from './SceneComponent'; // uses above component in same directory
//import SceneComponent from 'babylonjs-hook'; // if you install 'babylonjs-hook' NPM.
import {GLTFFileLoader} from 'babylonjs-loaders';

import styles from './Model.module.scss';
import { getResourcePath, RESOURCES_FOLDER } from '../Constants';



let box: any;
const onSceneReady = (filename: string, callBack: Function, zoom: number= 0.5) => (scene:any) => {
  // This creates and positions a free camera (non-mesh)
  //var camera = new ArcRotateCamera("camera1", new Vector3(0, 5, -10), scene);
  const camera = new ArcRotateCamera("camera1", 0, 0, 0,new Vector3(0, 5, -10), scene);
  camera.inputs.removeByType("ArcRotateCameraMouseWheelInput");

  const intensity = 0.6;
  
  // This targets the camera to scene origin
  camera.setTarget(Vector3.Zero());
  const canvas = scene.getEngine().getRenderingCanvas();
  // This attaches the camera to the canvas
  camera.attachControl(canvas, true);
  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  //const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
  // Default intensity is 1. Let's dim the light a small amount
  //light.intensity = 1;
  // Our built-in 'box' shape.
  //box = MeshBuilder.CreateBox("box", {size: 2}, scene);
  // Move the box upward 1/2 its height
  //box.position.y = 1;
  // Our built-in 'ground' shape.
  //MeshBuilder.CreateGround("ground", {width: 6, height: 6}, scene);

  scene.clearColor = new Color4(0,0,0,0.0000000000000001) ;

  //OBJFileLoader.COMPUTE_NORMALS = true;
  SceneLoader.RegisterPlugin(new GLTFFileLoader());
  SceneLoader.ImportMesh("", RESOURCES_FOLDER, getResourcePath('models', filename), scene, function (newMeshes) {
        // do something with the scene
        const dude = newMeshes[0];

        (dude.rotationQuaternion as any) = undefined;
        
        //dude.position = new Vector3(0, 0, -80);
        // Create a default arc rotate camera and light.
        scene.createDefaultCameraOrLight(true, true, true);
        const indexCamera = scene.cameras.findIndex((cam: any) => cam instanceof ArcRotateCamera);
        scene.cameras[indexCamera].inputs.removeByType("ArcRotateCameraMouseWheelInput");
        //(scene.cameras[0] as any).setPosition(new Vector3(0, 0, 2));
        const {x,y,z} = scene.cameras[indexCamera].position;
        const cords = [x, y, z];
        (scene.cameras[indexCamera] as any).setPosition(new Vector3(x, y, z + zoom));
        /*if(vector){
          (scene.cameras[0] as any).setPosition(new Vector3(vector[0], vector[1], vector[2]));
        }else {
          
        }*/
        
        //right light
        const light = new HemisphericLight("light", new Vector3(50, 0, 50), scene);
        light.intensity = intensity;

        //left light
        const light2 = new HemisphericLight("Omni", new Vector3(-50, 0, -50), scene);
        light2.intensity = intensity;

        //front light
        const light3 = new HemisphericLight("Omni2", new Vector3(0, -50, 50), scene);
        light3.intensity = intensity;

        //topLight
        const light4 = new HemisphericLight("Omni3", new Vector3(0, -50, 50), scene);
        light4.intensity = intensity;

        // backLIght
        const light5 = new HemisphericLight("Omni4", new Vector3(0, -50, 100), scene);
        light5.intensity = intensity;

        /*scene.beforeRender = () => {
            const [x1, y1, z1] = cords;
            const {x,y,z} = scene.cameras[0].position;
            if(x1 !== x || y1 !== y || z1 !== z){
              cords[0] = x;
              cords[1] = y;
              cords[2] = z;
              console.log(cords);
            }
            
        };*/

        //dude.rotation.y += 0.01;

        callBack();
  });
}
/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = (scene: any) => {
  if (box !== undefined) {
    var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    const rpm = 10;
    box.rotation.y += ((rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000));
  }
}

interface IModel {
  modelFile: string;
  onLoad: Function;
  variant?: 'small' | 'full';
  zoom?: number;
}

const Model: FC<IModel> = ({modelFile, onLoad, zoom = 0.5, variant='small'}) => {
  
  const [loading, setLoading] = useState(true);

  const onReadyCallBack = useMemo(() => onSceneReady(modelFile, () => {
    onLoad();
    setLoading(false);
  }, zoom), [modelFile, zoom]);

  return (
    <div className={`${styles[variant]} ${styles.container}`}>
      {loading && <div className={styles.spin}><div className={styles.spinner} /></div>}
      <SceneComponent antialias onSceneReady={onReadyCallBack} onRender={onRender} className={styles.viewer} />
    </div>
  )
};

export default Model;
