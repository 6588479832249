import { IFashionWeekState, IUser } from "./types/StoreTypes";

const initialState: IFashionWeekState = {
    isLoggedIn: false,
    knowsIsUser: false,
    isUser: false,
    user: {} as IUser,
    currentRoute: '/',
    managedRoute: true,
    isAdmin: false,
    knowsIsAdmin: false,
    isJudge: false,
    knowsIsJudge: false,
    isPreview: false,
    isMidec: false,
    isLobbyAudio: true,
    openRooms: []
};

export default initialState;
