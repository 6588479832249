import { gsap } from 'gsap';
import { lerp, getMousePos } from './utils';

// Track the mouse position
let mouse = {x: 0, y: 0};
window.addEventListener('mousemove', ev => mouse = getMousePos(ev));

export default class Cursor {
    frame = null;
    constructor(el, target = window) {
        this.DOM = {el: el};
        this.DOM.el.style.opacity = 0;
        
        this.bounds = this.DOM.el.getBoundingClientRect();
        
        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.2},
            ty: {previous: 0, current: 0, amt: 0.2}
        };

        this.onMouseMoveEv = () => {
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width/2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds.height/2;
            gsap.to(this.DOM.el, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});

            if(target === window) {
                this.frame = requestAnimationFrame(() => this.render());
            }

            target.removeEventListener('mousemove', this.onMouseMoveEv);
        };

        target.addEventListener('mousemove', this.onMouseMoveEv);

        if(target !== window){
            target.addEventListener('mouseenter', () => {
                if(this.frame === null) {
                    console.log('mouseEnter listener');
                    this.frame = requestAnimationFrame(() => this.render());
                    this.DOM.el.style.display = 'block';
                }
            });
            
            target.addEventListener('mouseout', () => {
                console.log('mouseOut listener');
                cancelAnimationFrame(this.frame);
                this.frame = null;
                this.DOM.el.style.display = 'none';
            });
        }

    }

    render() {
        this.renderedStyles['tx'].current = mouse.x - this.bounds.width/2;
        this.renderedStyles['ty'].current = mouse.y - this.bounds.height/2;

        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }
                    
        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px)`;
        
        this.frame = requestAnimationFrame(() => this.render());
    }
}
