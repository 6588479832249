import React, { FC } from 'react';
import I360Svg from '../../assets/images/I360Svg';
import styles from './I360.module.scss';

interface II360 {
    ref?: any;
}

const I360: FC<II360> = ({ref=null}) => (
    <div className={`icon ${styles.i360}`}>
        <I360Svg />
    </div>
);

export default I360;