import React, { FC, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getResource } from "../Constants";
import { useStateValue } from "../stores/main/main";

const AudioLobby:FC = () =>{
    const {pathname} = useLocation();
    const [{isLobbyAudio}] = useStateValue();
    const audioRef = useRef<any>();

    useEffect(()=> {

        const isPath = pathname === '/' || pathname.startsWith('/room/');
        if(isLobbyAudio && isPath) {
            audioRef.current.play();
        }else{
            audioRef.current.pause();
        }
    }, [isLobbyAudio, pathname, audioRef])

    return useMemo(() => (
        <audio controls={false} loop={true} ref={audioRef} preload={'auto'}>
          <source src={getResource('audio', 'lobby.ogg')} type="audio/ogg" />
        </audio>
    ), []);
}

export default AudioLobby;
