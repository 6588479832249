import React, { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import CursorClass from '../../lib/images/cursor';

interface ICursor {
    targetRef?: MutableRefObject<any>
}

const Cursor: FC<ICursor> = ({targetRef})=> {

    const cursorRef = useRef<HTMLOrSVGElement | null>(null);

    useEffect(() => {
        if(cursorRef?.current) {
            new CursorClass(cursorRef?.current, targetRef?.current ?? window);
        }
    }, [cursorRef, targetRef])

    return useMemo(() => (
        <svg className="cursor" width="80" height="80" viewBox="0 0 80 80" ref={cursorRef as any}>
            <circle className="cursor__inner" cx="40" cy="40" r="20" />
        </svg>
    ), [cursorRef]);
}

export default Cursor;
