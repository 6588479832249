import React, { useEffect, useState } from 'react';
import { useStateValue } from '../stores/main/main';

export const useViewLobby = () => {
    const [viewLobby, setViewLobby] = useState(false);
    const [{isAdmin, isPreview}] = useStateValue();

    useEffect(() => {
        setViewLobby(true);
    }, [setViewLobby, isAdmin, isPreview]);

    return viewLobby;
}
