import React, { useMemo } from 'react';

export const usePrefix = () => {
    const prefix = 'event';

    return prefix;
}

export const useKey = (key: string) => {
    const prefix = usePrefix();

    return useMemo(() => `${prefix}-${key}`, [prefix, key]);
}
