import React, { useCallback, useEffect } from "react"

export const useAnalyticsEnter = (page: string, value: string) =>{
    useEffect(()=> {

        return () => {
        }
    }, [page, value]);
}

export const useLog = (eventName: string) => {
    return useCallback((value?: any) => null, [eventName]);
}
