import React, { useEffect } from 'react';
import { mousePosListener } from '../lib/images/menuItem';

export const useMenuItem = () => {
    useEffect(() => {
        // update mouse position when moving the mouse
        window.addEventListener('mousemove',mousePosListener );

        return () => window.removeEventListener('mousemove',mousePosListener );
    }, []);
}