import React, {createContext, useContext, useReducer, Dispatch} from 'react';
import initialState from './initialState';
import { IFashionWeekAction, IFashionWeekState } from './types/StoreTypes';


export const StateContext = createContext(initialState);

export const StateProvider= ( {reducer, initialState, children}: any ) => (
  <StateContext.Provider value={useReducer(reducer, initialState) as any}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = (): [IFashionWeekState, Dispatch<IFashionWeekAction>] => useContext(StateContext) as any;