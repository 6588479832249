import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {useOpenRooms, useRoom} from '../../hooks/roomHooks';

import {useMenuItem} from '../../hooks/menuHooks';
import {preloader} from '../../lib/images/preloader';
import Cursor from '../../components/layout/Cursor';
import Menu from '../../lib/images/menu';

import styles from './Room.module.scss';
import Header from '../../components/layout/Header';
import Hr from '../../components/shared/Hr';
import { useAnalyticsEnter } from '../../hooks/analyticsHooks';
import { getResource } from '../../Constants';

const Room: FC = () => {
  const {roomId}: any = useParams();
  const history = useHistory();
  const room = useRoom(roomId);
  const {isManagedRooms, openRooms} = useOpenRooms();
  
  const locomotiveRef = useRef<HTMLElement>();
  useMenuItem();

  useAnalyticsEnter('/room', roomId);

  useEffect(() => {
    if (locomotiveRef.current) {
      const menuEl = locomotiveRef.current?.querySelector('.menu');
      preloader('.menu__item').then(() => {

        new Menu(menuEl);
      });
    }
  }, [locomotiveRef]);

  const menuClick = useCallback((e)=> {
    e.stopPropagation();

  }, []);

  const screenClick = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <section className={styles.roomContainer} style={{backgroundImage:`url(${getResource('rooms', room?.background)})`}} onClick={screenClick}>
      <div className={styles.roomMask}>
      <Cursor />
      <Header variant="box" />
      <header>
        <h1 className={`${styles.title} is-bold`}>
          <span style={{color: room?.color}}>{room?.roomId}</span>
          {room?.name}
        </h1>
        <Hr className={styles.hr} />
      </header>
      <section ref={locomotiveRef as any} >
        <main>
          <nav className="menu">
            {room?.designers.map(({name, id, brandName, hoverImage}) => (
              (!isManagedRooms || openRooms.includes(parseInt(id)) ) ?
              <Link
                to={`/designer/${id}`}
                className="menu__item"
                data-img={getResource('scenes', hoverImage)}
                key={`link-designer-${id}`}
                onClick={menuClick}
              >
                <span className="menu__item-text">
                  <span className="menu__item-textinner">{name}</span>
                </span>
                <span className="menu__item-sub">{brandName}</span>
              </Link> :
              <span
              className="menu__item"
              data-img={getResource('scenes', hoverImage)}
              key={`link-designer-${id}`}
              onClick={menuClick}
            >
                <span className="menu__item-text">
                  <span className="menu__item-textinner">{name}</span>
                </span>
                <span className="menu__item-sub">{brandName}</span>
              </span>
            ))}
          </nav>
        </main>
      </section>
      </div>
    </section>
  );
};

export default Room;
