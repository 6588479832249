import React, {FC, useState} from 'react';
import Video360 from '../../components/video/video360';
import { IDesigner } from '../../types';

interface IView360 {
    designer: IDesigner,
    onLoad: Function;
    canPlay: boolean;
}

export const View360: FC<IView360> = ({designer: {video360, id, audio, audioStart}, onLoad, canPlay, ...props}) => {

    return (<section className="hero is-success is-fullheight is-success" style={{position: 'relative'}} {...props}>
        <div >
            <Video360 video={video360} onLoad={onLoad} canPlay={canPlay} audio={audio || ''} audioStart={audioStart} />
        </div>
    </section>)
}
