import React, { FC } from "react";
import styles from './Hr.module.scss';

interface IHr{
    variant?: 'left' | 'right';
    className?: string;
}

const Hr: FC<IHr> = ({variant = 'left', className='', ...props}) => (
    <div className={`${styles.fakeHr} ${styles[variant]} ${className}`} {...props}>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
    </div>
)


export default Hr;
