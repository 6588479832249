import React, { useEffect, useMemo, useState } from 'react';
import { useSetOpenRooms } from '../stores/main/actions/roomsActions';
import { useStateValue } from '../stores/main/main';
import { IRoom } from '../types/room.types';
import rooms from '../_data/rooms.data';

export const useRoom = (roomId: string) => {
    const [room, setRoom] = useState<IRoom>();
    useEffect(() => {
        setRoom(rooms[roomId]);
    }, [roomId, setRoom]);
    return room;
}

export const useOpenRooms = () => {
    const setOpenRooms = useSetOpenRooms();
    const [{openRooms, managedRoute, isAdmin}] = useStateValue();
    

    const isManagedRooms = false;

    return {openRooms, isManagedRooms};
}

